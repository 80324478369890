interface DataObject {
  event: string;
  action?: string; // Make 'action' optional
}

export default function pushToDataLayer(
  eventType: string,
  action: string,
  /* eslint-disable @typescript-eslint/no-explicit-any */
  data?: Record<string, any>
) {
  const dataObject: DataObject = {
    event: eventType,
    ...data
  };

  if (action) {
    dataObject.action = action;
  }
  if (window.dataLayer) {
    window.dataLayer.push(dataObject);
  }
}
