import { createStore, compose, Store } from "redux";
import { AppAction, appReducer, AppState } from "./app";
export let store: Store<AppState, AppAction>;

const preloadedState = (
  preloadedState: AppState
): Store<AppState, AppAction> => {
  const hasWindow = typeof window !== "undefined";
  const composeEnhancers =
    hasWindow && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== "undefined"
      ? window.__REDUX_DEVTOOLS_EXTENSION__
      : compose;

  store = createStore(appReducer, preloadedState, composeEnhancers());
  return store;
};

export default preloadedState;
