import { IGatsbyImageData } from "gatsby-plugin-image";
import { Target } from "utils/openapi-snippet";
import { NetsTarget } from "utils/snippets";
import { NetsProduct } from "components/layout";

export type ProgrammingLanguage = Target;
export type GuideConfigurations = {
  [Key in string]: {
    [Key in string]: NetsTarget;
  };
};
export type LocalePickerMode = "region" | "language";
export type LocalePicker = {
  open: boolean;
  mode: LocalePickerMode;
};
export type I18n = {
  picker: LocalePicker;
  locales: GatsbyTypes.LocaleNodeFragment[];
  locale: string;
};

export type SmallSiteNavigationView =
  | "default"
  | "search"
  | "menu"
  | "locale"
  | "image-popup";

export interface ImageItem {
  alt: string;
  id: string;
  image: IGatsbyImageData;
}

type ItemId = string;

export interface ExpandableItem {
  id: ItemId;
  expanded: boolean;
  parent?: ItemId;
  children?: ItemId[];
}

export type ExpandableItems = Record<string, ExpandableItem[]>;

export type PortalTheme = "light" | "dark" | "api";

// Our App State object
export type AppState = {
  programmingLanguage: ProgrammingLanguage;
  scrollSpy: string | undefined;
  guideConfigurations: GuideConfigurations;
  i18n: I18n;
  smallSiteNavigationView: SmallSiteNavigationView;
  imagePopup?: ImageItem;
  deeplink?: string;
  expandableItems: ExpandableItems;
  clickedNavigationItem?: string;
  product?: NetsProduct;
  pending?: boolean;
  numberOfBanners: number;
  visibleFooterPixels: number;
  isAuthenticated?: boolean;
  portalTheme: PortalTheme;
};

// The different types of actions that our reducer can perform
export enum ActionTypes {
  SetProgrammingLanguage = "SET_PROGRAMMING_LANGUAGE",
  SetScrollSpy = "SET_SCROLL_SPY",
  SetGuideConfigurations = "SET_GUIDE_CONFIGURATIONS",
  SetLocalePicker = "SET_LOCALE_PICKER",
  SetLocaleAndLocales = "SET_LOCALE_AND_LOCALES",
  SetSmallSiteNavigationView = "SET_SMALL_SITE_NAVIGATION_VIEW",
  SetImagePopup = "SET_IMAGE_POPUP",
  SetDeeplink = "SET_DEEPLINK",
  SetExpandableGroup = "SET_EXPANDABLE_GROUP",
  ToggleItemExpanded = "TOGGLE_Item_EXPANDED",
  SetGroupExpanded = "SET_PROPERTIES_GROUP_EXPANDED",
  ExpandAllItems = "EXPAND_ALL_ITEMS",
  ExpandItemParents = "EXPAND_ITEM_PARENTS",
  RemoveExpandableGroup = "REMOVE_EXPANDABLE_GROUP",
  SetClickedNavigationItem = "SET_CLICKED_NAVIGATION_ITEM",
  SetProduct = "SET_PRODUCT",
  SetPending = "SET_PENDING",
  SetNumberOfBanners = "SET_NUMBER_OF_BANNERS",
  SetVisibleFooterPixels = "SET_VISIBLE_FOOTER_PIXELS",
  SetIsAuthenticated = "SET_IS_AUTHENTICATED",
  SetPortalTheme = "SET_PORTAL_THEME"
}

// Discriminating Union types for our actions
export type ActionSetProgrammingLanguage = {
  type: ActionTypes.SetProgrammingLanguage;
  payload: Pick<AppState, "programmingLanguage">;
};

export type ActionSetScrollSpy = {
  type: ActionTypes.SetScrollSpy;
  payload: { id: string };
};

export type ActionSetGuideConfigurations = {
  type: ActionTypes.SetGuideConfigurations;
  payload: Pick<AppState, "guideConfigurations">;
};

export type ActionSetLocaleAndLocales = {
  type: ActionTypes.SetLocaleAndLocales;
  payload: Pick<I18n, "locale" | "locales">;
};

export type ActionSetLocalePicker = {
  type: ActionTypes.SetLocalePicker;
  payload: LocalePicker;
};

export type ActionSetSmallSiteNavigationView = {
  type: ActionTypes.SetSmallSiteNavigationView;
  payload: SmallSiteNavigationView;
};

export type ActionSetImagePopup = {
  type: ActionTypes.SetImagePopup;
  payload: ImageItem;
};

export type ActionSetDeeplink = {
  type: ActionTypes.SetDeeplink;
  payload: string;
};

export type ActionSetParameterItemGroup = {
  type: ActionTypes.SetExpandableGroup;
  payload: ExpandableItems;
};

export type ActionToggleItemExpanded = {
  type: ActionTypes.ToggleItemExpanded;
  payload: { group: string; id: ItemId };
};

export type ActionSetGroupExpanded = {
  type: ActionTypes.SetGroupExpanded;
  payload: { group: string; expanded: boolean };
};

export type ActionExpandAllItems = {
  type: ActionTypes.ExpandAllItems;
};

export type ActionExpandItemParents = {
  type: ActionTypes.ExpandItemParents;
  payload: { group: string; id: string };
};

export type ActionRemoveExpandableGroup = {
  type: ActionTypes.RemoveExpandableGroup;
  payload: { group: string };
};
export type ActionSetClickedNavigationItem = {
  type: ActionTypes.SetClickedNavigationItem;
  payload: string;
};

export type ActionSetProduct = {
  type: ActionTypes.SetProduct;
  payload: NetsProduct;
};

export type ActionSetPending = {
  type: ActionTypes.SetPending;
  payload: boolean;
};

export type ActionSetVisibleFooterPixels = {
  type: ActionTypes.SetVisibleFooterPixels;
  payload: number;
};

export type ActionSetIsAuthenticated = {
  type: ActionTypes.SetIsAuthenticated;
  payload: boolean;
};

export type ActionSetPortalTheme = {
  type: ActionTypes.SetPortalTheme;
  payload: PortalTheme;
};

// Inital state for our AppState
export const appInitialState: AppState = {
  programmingLanguage: "csharp",
  scrollSpy: undefined,
  guideConfigurations: {},
  i18n: {
    locale: "en-EU",
    locales: [],
    picker: {
      open: false,
      mode: "region"
    }
  },
  smallSiteNavigationView: "default",
  imagePopup: undefined,
  deeplink: undefined,
  expandableItems: {},
  clickedNavigationItem: undefined,
  product: undefined,
  pending: false,
  numberOfBanners: 0,
  visibleFooterPixels: -1,
  isAuthenticated: undefined,
  portalTheme: "light"
};

// The different acceptable actions for our reducer
export type AppAction =
  | ActionSetProgrammingLanguage
  | ActionSetScrollSpy
  | ActionSetGuideConfigurations
  | ActionSetLocalePicker
  | ActionSetLocaleAndLocales
  | ActionSetImagePopup
  | ActionSetSmallSiteNavigationView
  | ActionSetDeeplink
  | ActionSetParameterItemGroup
  | ActionToggleItemExpanded
  | ActionSetGroupExpanded
  | ActionExpandAllItems
  | ActionExpandItemParents
  | ActionRemoveExpandableGroup
  | ActionSetProduct
  | ActionSetClickedNavigationItem
  | ActionSetPending
  | ActionSetVisibleFooterPixels
  | ActionSetIsAuthenticated
  | ActionSetPortalTheme;

// App State reducer, updates the state based on incoming actions
export const appReducer = (
  state = appInitialState,
  action: AppAction
): AppState => {
  switch (action.type) {
    case ActionTypes.SetProgrammingLanguage:
      return {
        ...state,
        programmingLanguage: action.payload.programmingLanguage
      };
    case ActionTypes.SetScrollSpy:
      return {
        ...state,
        scrollSpy: action.payload.id
      };
    case ActionTypes.SetGuideConfigurations:
      return {
        ...state,
        guideConfigurations: action.payload.guideConfigurations
      };
    case ActionTypes.SetLocaleAndLocales:
      return {
        ...state,
        i18n: {
          ...state.i18n,
          ...action.payload
        }
      };
    case ActionTypes.SetLocalePicker:
      return {
        ...state,
        i18n: {
          ...state.i18n,
          picker: {
            ...state.i18n.picker,
            ...action.payload
          }
        }
      };
    case ActionTypes.SetSmallSiteNavigationView:
      return {
        ...state,
        smallSiteNavigationView: action.payload
      };
    case ActionTypes.SetImagePopup:
      return {
        ...state,
        imagePopup: action.payload,
        smallSiteNavigationView: action.payload ? "image-popup" : "default"
      };
    case ActionTypes.SetDeeplink:
      return {
        ...state,
        deeplink: action.payload
      };
    case ActionTypes.SetExpandableGroup:
      return {
        ...state,
        expandableItems: {
          ...state.expandableItems,
          ...action.payload
        }
      };
    case ActionTypes.ToggleItemExpanded:
      return {
        ...state,
        expandableItems: {
          ...state.expandableItems,
          [action.payload.group]: state.expandableItems[
            action.payload.group
          ]?.map((item) =>
            item.id === action.payload.id
              ? {
                  ...item,
                  expanded: !item.expanded
                }
              : item
          )
        }
      };
    case ActionTypes.SetGroupExpanded:
      return {
        ...state,
        expandableItems: {
          ...state.expandableItems,
          [action.payload.group]: state.expandableItems[
            action.payload.group
          ].map((item) => ({
            ...item,
            expanded: action.payload.expanded
          }))
        }
      };
    case ActionTypes.ExpandAllItems:
      return {
        ...state,
        expandableItems: Object.fromEntries(
          Object.entries(state.expandableItems).map(
            ([groupName, properties]) => [
              groupName,
              properties.map((item) => ({
                ...item,
                expanded: true
              }))
            ]
          )
        )
      };
    case ActionTypes.ExpandItemParents:
      return {
        ...state,
        expandableItems: {
          ...state.expandableItems,
          [action.payload.group]: recursivlyUpdateParents(
            state.expandableItems[action.payload.group],
            action.payload.id
          )
        }
      };
    case ActionTypes.RemoveExpandableGroup:
      return {
        ...state,
        expandableItems: Object.fromEntries(
          Object.entries(state.expandableItems).filter(
            (group) => group[0] !== action.payload.group
          )
        )
      };
    case ActionTypes.SetClickedNavigationItem:
      return {
        ...state,
        clickedNavigationItem: action.payload
      };
    case ActionTypes.SetProduct:
      return {
        ...state,
        product: action.payload
      };
    case ActionTypes.SetPending:
      return {
        ...state,
        pending: action.payload
      };
    case ActionTypes.SetVisibleFooterPixels:
      return {
        ...state,
        visibleFooterPixels: action.payload
      };
    case ActionTypes.SetIsAuthenticated:
      return {
        ...state,
        isAuthenticated: action.payload
      };
    case ActionTypes.SetPortalTheme:
      return {
        ...state,
        portalTheme: action.payload
      };
    default:
      return state;
  }
};

const recursivlyUpdateParents = (
  items: ExpandableItem[],
  id: string
): ExpandableItem[] => {
  if (!items) return items;
  const parent = items.find((prop) => prop.id === id)?.parent;
  if (!parent) return items;

  return recursivlyUpdateParents(
    items.map((item) =>
      item.id === parent ? { ...item, expanded: true } : item
    ),
    parent
  );
};
