import React, { FC } from "react";
import { Provider } from "react-redux";
import {
  WrapRootElementBrowserArgs,
  RouteUpdateArgs,
  PluginOptions
} from "gatsby";
import createStore, { store } from "./src/state/createStore";
import { ActionTypes, appInitialState, AppState } from "./src/state";
import { compose } from "redux";
import NProgress from "nprogress";
import "./static/fonts.css";
import "./src/styles/global.scss";
import pushToDataLayer from "utils/gtm";
declare global {
  interface Window {
    __PRELOADED_STATE__?: AppState;
    __REDUX_DEVTOOLS_EXTENSION__?: typeof compose;
  }
}

export const wrapRootElement: FC<WrapRootElementBrowserArgs> = ({
  element
}) => {
  const preloadedState = window.__PRELOADED_STATE__ || appInitialState;
  const store = createStore({
    ...preloadedState,
    deeplink:
      window.location.hash !== ""
        ? window.location.hash.replace("#", "")
        : undefined
  });

  store.subscribe(() => {
    const currentState = store.getState();

    if (currentState.pending) {
      if (!NProgress.isStarted()) {
        NProgress.start();
      }
    }
  });
  const Comp = () => <React.Fragment>{element}</React.Fragment>;

  return (
    <Provider store={store}>
      <Comp />
    </Provider>
  );
};

export const onInitialClientRender = (): void => {
  delete window.__PRELOADED_STATE__;

  const preloadedStateEl = document.getElementById("redux-ssr");
  if (preloadedStateEl) {
    preloadedStateEl.parentNode?.removeChild(preloadedStateEl);
  }
};

export const onRouteUpdateDelayed = (): void => {
  if (!NProgress.isStarted()) {
    NProgress.start();
  }
};

export const onRouteUpdate = (
  { location, prevLocation }: RouteUpdateArgs,
  options: PluginOptions
): void => {
  // Only setting deeplink in state if it goes to a place that might likely need to useScrollHere (Request body parameters, Responses)
  // or if the user is going to a new page

  NProgress.done();
  store.dispatch({
    type: ActionTypes.SetPending,
    payload: false
  });

  if (
    location.hash !== undefined &&
    (location.pathname !== prevLocation?.pathname ||
      location.hash.includes("-body-") ||
      location.hash.includes("-responses-"))
  ) {
    pushToDataLayer("virtual_pageview", "", {
      page: {
        path: location.pathname.replace(/#/g, ""),
        title: document.title
      },
      http: { response: { code: "200" } }
    });
    store.dispatch({
      type: ActionTypes.SetDeeplink,
      payload: location.hash.replace("#", "")
    });
  }
};

const color = "#00bef0";
export const onClientEntry = (): void => {
  // Inject styles.
  const styles = `
    #nprogress {
     pointer-events: none;
    }
    #nprogress .bar {
      background: ${color};
      position: fixed;
      z-index: 1031;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
    }
    #nprogress .peg {
      display: block;
      position: absolute;
      right: 0px;
      width: 100px;
      height: 100%;
      box-shadow: 0 0 10px ${color}, 0 0 5px ${color};
      opacity: 1.0;
      -webkit-transform: rotate(3deg) translate(0px, -4px);
      -ms-transform: rotate(3deg) translate(0px, -4px);
      transform: rotate(3deg) translate(0px, -4px);
    }
    #nprogress.spinner {
      display: none;
    }
    #nprogress .spinner-icon {
      width: 18px;
      height: 18px;
      box-sizing: border-box;
      border: solid 2px transparent;
      border-top-color: ${color};
      border-left-color: ${color};
      border-radius: 50%;
      -webkit-animation: nprogress-spinner 400ms linear infinite;
      animation: nprogress-spinner 400ms linear infinite;
    }
    .nprogress-custom-parent {
      overflow: hidden;
      position: relative;
    }
    .nprogress-custom-parent #nprogress .spinner,
    .nprogress-custom-parent #nprogress .bar {
      position: absolute;
    }
    @-webkit-keyframes nprogress-spinner {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes nprogress-spinner {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `;

  const node = document.createElement(`style`);
  node.id = `nprogress-styles`;
  node.innerHTML = styles;
  document.head.appendChild(node);

  NProgress.start();
};
