exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-status-tsx": () => import("./../../../src/pages/status.tsx" /* webpackChunkName: "component---src-pages-status-tsx" */),
  "component---src-templates-api-overview-api-overview-tsx": () => import("./../../../src/templates/api-overview/api-overview.tsx" /* webpackChunkName: "component---src-templates-api-overview-api-overview-tsx" */),
  "component---src-templates-api-reference-api-reference-tsx": () => import("./../../../src/templates/api-reference/api-reference.tsx" /* webpackChunkName: "component---src-templates-api-reference-api-reference-tsx" */),
  "component---src-templates-change-log-current-tsx": () => import("./../../../src/templates/change-log/current.tsx" /* webpackChunkName: "component---src-templates-change-log-current-tsx" */),
  "component---src-templates-change-log-future-tsx": () => import("./../../../src/templates/change-log/future.tsx" /* webpackChunkName: "component---src-templates-change-log-future-tsx" */),
  "component---src-templates-content-page-content-page-tsx": () => import("./../../../src/templates/content-page/content-page.tsx" /* webpackChunkName: "component---src-templates-content-page-content-page-tsx" */),
  "component---src-templates-contentful-api-reference-contentful-api-reference-tsx": () => import("./../../../src/templates/contentful-api-reference/contentful-api-reference.tsx" /* webpackChunkName: "component---src-templates-contentful-api-reference-contentful-api-reference-tsx" */),
  "component---src-templates-guide-guide-tsx": () => import("./../../../src/templates/guide/guide.tsx" /* webpackChunkName: "component---src-templates-guide-guide-tsx" */),
  "component---src-templates-redirect-page-redirect-page-tsx": () => import("./../../../src/templates/redirect-page/redirect-page.tsx" /* webpackChunkName: "component---src-templates-redirect-page-redirect-page-tsx" */),
  "component---src-templates-search-page-search-page-tsx": () => import("./../../../src/templates/search-page/search-page.tsx" /* webpackChunkName: "component---src-templates-search-page-search-page-tsx" */),
  "component---src-templates-start-page-start-page-tsx": () => import("./../../../src/templates/start-page/start-page.tsx" /* webpackChunkName: "component---src-templates-start-page-start-page-tsx" */),
  "component---src-templates-support-page-faq-page-tsx": () => import("./../../../src/templates/support-page/faq-page.tsx" /* webpackChunkName: "component---src-templates-support-page-faq-page-tsx" */),
  "component---src-templates-support-page-support-page-tsx": () => import("./../../../src/templates/support-page/support-page.tsx" /* webpackChunkName: "component---src-templates-support-page-support-page-tsx" */),
  "component---src-templates-support-page-test-support-page-test-tsx": () => import("./../../../src/templates/support-page-test/support-page-test.tsx" /* webpackChunkName: "component---src-templates-support-page-test-support-page-test-tsx" */)
}

